import { Avatar, Button, Paper, Typography } from "@material-ui/core"
import React from "react"

const ServicePick = ({ data, pickedUser, setPickedUser }) => {
  console.log("ServicePick", { data, pickedUser })
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 10,
        padding: 10,
        border: data.id === pickedUser?.id ? "1px solid rgba(255,126,126)" : "",
        width: "100%",
      }}
      onClick={() => setPickedUser(data)}
      component={Button}
    >
      <Avatar
        style={{ width: 60, height: 60 }}
        src={data.image || data.picture}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: 10,
          flex: 1,
          alignItems: "flex-start",
        }}
      >
        <Typography>{data.given_name}</Typography>
        <Typography
          align="left"
          variant="body2"
          style={{ textTransform: "none" }}
        >
          {data.shortDescription}
        </Typography>
      </div>
    </Paper>
  )
}
export default function UserPicker({
  pickedUser,
  setPickedUser,
  workUsers,
  handleBack,
  handleNext,
}) {
  return (
    <Paper
      style={{
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
        padding: 10,
        maxWidth: 600,
        width: "100%",
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Choisissez votre conseiller
      </Typography>
      {workUsers.map(value => (
        <ServicePick
          key={value.user.id}
          data={value.user}
          pickedUser={pickedUser}
          setPickedUser={setPickedUser}
        />
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={handleBack}
          style={{
            background: "white",
            border: "1px solid rgb(255,126,126)",
          }}
        >
          <Typography color="secondary">Retour</Typography>
        </Button>
        <div style={{ width: 10 }} />

        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          disabled={!pickedUser}
        >
          <Typography style={{ color: "white" }}>Suivant</Typography>
        </Button>
      </div>
    </Paper>
  )
}
