import React from "react"
import PropTypes from "prop-types"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import PersonIcon from "@material-ui/icons/Person"
import DateRangeIcon from "@material-ui/icons/DateRange"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import CheckIcon from "@material-ui/icons/Check"
import StepConnector from "@material-ui/core/StepConnector"
import { StepButton } from "@material-ui/core"

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      background: "rgb(9,45,92)",
      //   backgroundImage: `linear-gradient( 95deg, rgb(9,45,92) 0%, #ff6363 100%)`,
    },
  },
  completed: {
    "& $line": {
      background: "rgb(9,45,92)",
      //   backgroundImage: `linear-gradient( 95deg, rgb(9,45,92) 0%, #ff6363 100%)`,
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "white",
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles(theme => {
  console.log(theme.palette.primary.main)
  return {
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      background: theme.palette.primary.main,
      //   backgroundImage: `linear-gradient( 136deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      background: theme.palette.primary.main,
      //   backgroundImage: `linear-gradient( 136deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
    },
  }
})

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <PersonIcon />,
    2: <DateRangeIcon />,
    3: <FormatListBulletedIcon />,
    4: <CheckIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

export default function CustomizedStepper({
  activeStep,
  steps,
  setActiveStep,
}) {
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
      style={{
        background: "transparent",
        margin: 0,
        padding: 0,
        alignSelf: "center",
      }}
    >
      {steps.map((label, index) => {
        const disabled = activeStep < index || activeStep === 3
        const onClick = () => setActiveStep(index)
        const active = index === activeStep
        const completed = index <= activeStep
        const iconIndex = index + 1
        const Icon = (
          <ColorlibStepIcon
            icon={iconIndex}
            active={active}
            completed={completed}
          />
        )
        return (
          <Step key={label}>
            <StepButton disabled={disabled} onClick={onClick} icon={Icon}>
              <StepLabel>{label}</StepLabel>
            </StepButton>
          </Step>
        )
      })}
    </Stepper>
  )
}
