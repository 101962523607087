import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import Layout from "./layout"
import SEO from "./seo"
import LoadingBackdrop from "./LoadingBackDrop"
import { navigate } from "gatsby"
import { API } from "aws-amplify"
import { getBookableResources, getProjectResources } from "./ProjectDetails"
import { restUnauth } from "../services/rest"
import CustomizedStepper from "./CustomStepper"
import UserPicker from "./UserPicker"
import SlotPicker from "./SlotPicker"
import CustomerBookingForm from "./CustomerBookingForm"
import BookingConfirmStep from "./BookingConfirmStep"
import { isPossiblePhoneNumber } from "react-phone-number-input"

const { addMinutes, isWithinInterval } = require("date-fns")

const timeWindowsToSlots = (tw, bookLength = 15) => {
  const slots = []
  tw.map(({ start, end }) => ({
    start: new Date(start),
    end: new Date(end),
  })).forEach(t => {
    let s = { start: t.start, end: addMinutes(t.start, bookLength) }
    while (isWithinInterval(s.start, t) && isWithinInterval(s.end, t)) {
      slots.push({ start: s.start, end: s.end })
      s = {
        start: addMinutes(s.start, bookLength),
        end: addMinutes(s.end, bookLength),
      }
    }
  })
  var filtered = slots.filter(slot => slot.start > new Date())
  filtered.sort((a, b) => a.start - b.start)
  return filtered
}

const useStyles = makeStyles(theme => ({
  stepLabel: {
    label: {
      "&$completed": {
        color: "pink",
      },
      "&$active": {
        color: "red",
      },
    },
  },
  root: {
    "& .MuiStepLabel-active": {
      fontWeight: theme.typography.fontWeightBold,
      // color: theme.palette.primary.main,
    },
    "& .MuiStepLabel-completed": {
      fontWeight: theme.typography.fontWeightBold,
      // color: theme.palette.primary.main,
    },
    "& .MuiStepIcon-text": {
      // fontSize: 24,
      fill: theme.palette.primary.main,
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiStepIcon-root": {
      width: "1.5em",
      height: "1.5em",
      fontSize: "2rem",
      backgroundColor: "rgb(255,255,255)",
      color: "rgb(255,255,255)",
      borderRadius: "50%",
    },
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexGrow: 1,
    background: "rgb(240,240,245)",
    paddingTop: 10,
    overflowY: "auto",
    overflowX: "hidden",
    // color: theme.palette.primary.main,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return ["Votre conseiller", "Votre horaire", "Vos infos", "Validation"]
}

export default function BookStepper({ data }) {
  const classes = useStyles()
  const location = useLocation()
  const { bookType } = queryString.parse(location.search)
  const [activeStep, setActiveStep] = React.useState(0)
  const [pickedUser, setPickedUser] = React.useState()
  const [pickedCalendarDate, setPickedCalendarDate] = React.useState()
  const [pickedSlot, setPickedSlot] = React.useState()
  const [givenName, setGivenName] = React.useState("")
  const [familyName, setFamilyName] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [validPhone, setValidPhone] = React.useState(false)
  const [bookReason, setBookReason] = React.useState("")
  const [email, setEmail] = React.useState("")
  const steps = getSteps()
  // const [pickedDate, setPickedDate] = React.useState()
  // const [pickedTime, setPickedTime] = React.useState()
  const [loading, setLoading] = React.useState()
  // const [error, setError] = React.useState()

  // const [availabilityLoading, setAvailabilityLoading] = React.useState(false)
  // const [availabilityError, setAvailabilityError] = React.useState()
  const [availabilitySlots, setAvailabilitySlots] = React.useState([])
  const [bookableResources, setBookableResources] = React.useState([])
  // const [availability, setAvailability] = React.useState([])
  // const [pickedWorker, setPickedWorker] = React.useState()
  // const [name, setName] = React.useState("")
  // const [email, setEmail] = React.useState("")
  // const [bookReason, setBookReason] = React.useState("")
  console.log("stepper", data, { bookType })
  const { project } = data
  const { id } = project
  // const {
  //   pickedWorker,
  //   pickedDate,
  //   pickedTime,
  //   name,
  //   email,
  //   phone,
  //   given_name,
  //   bookReason,
  // } = formValues
  React.useEffect(() => {
    console.log("fetch resources for booktype")
    API.graphql({
      query: getProjectResources,
      variables: { id },
      authMode: "AWS_IAM",
    })
      .then(p => {
        const res = getBookableResources(p.data.getProject).filter(
          r => r.resource.resourceType === bookType
        )
        setBookableResources(res)
      })
      .catch(err => {
        console.warn(err)
      })
  }, [id, bookType])

  const autoPickOnlySeller = () => {
    if (
      bookableResources.length > 0 &&
      bookableResources[0].resource.workUsers.length === 1
    ) {
      // setFormValue("pickedWorker")(
      //   bookableResources[0].resource.workUsers[0].user
      // )
      setPickedUser(bookableResources[0].resource.workUsers[0].user)
    }
  }

  React.useEffect(() => {
    setValidPhone(phone ? isPossiblePhoneNumber(phone) : false)
  }, [phone])

  React.useEffect(() => {
    autoPickOnlySeller()
  }, [bookableResources])

  React.useEffect(() => {
    try {
      availabilitySlots &&
        availabilitySlots.length > 0 &&
        setPickedCalendarDate(availabilitySlots[0].start)
      // setFormValues(f => ({ ...f, pickedDate: availabilitySlots[0].start }))
    } catch (e) {
      setPickedCalendarDate(null)
    }
  }, [availabilitySlots])

  React.useEffect(() => {
    if (!pickedUser) return
    setLoading(true)
    // setError()
    setAvailabilitySlots()
    console.log("fetching availability", {
      project,
      pickedUser,
      shopAvailability: data.project.availability,
      bookType,
    })
    restUnauth("post", "/open/availability", {
      project,
      pickedWorker: pickedUser,
      shopAvailability: data.project.availability,
      bookType,
    })
      .then(data => {
        console.log({ data })
        const { timeWindows } = data
        const bookDuration = JSON.parse(
          bookableResources[0].resource.bookDuration
        ).minutes
        console.log({ bookDuration })
        setAvailabilitySlots(timeWindowsToSlots(timeWindows, bookDuration))
        setLoading(false)
      })
      .catch(err => {
        console.warn(err)
        setLoading(false)
      })
  }, [pickedUser, bookType, data.project.availability, project])

  const handleNext = async () => {
    try {
      if (activeStep === 2) {
        setLoading(true)
        const response = await restUnauth("post", "/open/initBooking", {
          name: familyName,
          given_name: givenName,
          email,
          project,
          bookReason,
          pickedWorker: pickedUser,
          pickedTime: pickedSlot,
          bookType,
          phone,
          resource: bookableResources[0].resource,
        })
        if (response.error) {
          console.warn(response.error)
          setLoading(false)
          return
        }
        console.log({ response })
        setLoading(false)
      }
      window.scrollTo(0, 0)
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } catch (err) {
      console.warn(err)
      setLoading(false)
    }
  }

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(-1)
      return
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  // const slotDuration = 15 // 15 minutes

  // const availableSlotsForPickedDate =
  //   pickedCalendarDate && availabilitySlots
  //     ? availabilitySlots.filter(
  //         ({ start }) =>
  //           start.toLocaleDateString() ===
  //           pickedCalendarDate.toLocaleDateString()
  //       )
  //     : []

  // console.log({
  //   availabilitySlots,
  //   availableSlotsForPickedDate,
  //   pickedUser,
  //   bookableResources,
  //   pickedCalendarDate,
  //   pickedSlot,
  //   loading,
  //   activeStep,
  // })

  const workUsers =
    bookableResources.length > 0 ? bookableResources[0].resource.workUsers : []

  return (
    <Layout>
      <SEO title={`Prenez rendez-vous chez ${project.name}`} />
      <div className={classes.root}>
        <div style={{ alignSelf: "center", maxWidth: 600, width: "100%" }}>
          <CustomizedStepper
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
          />
        </div>
        <div style={{ height: 10 }} />
        {activeStep === 0 ? (
          <UserPicker
            pickedUser={pickedUser}
            setPickedUser={setPickedUser}
            workUsers={workUsers}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : null}
        {activeStep === 1 ? (
          <SlotPicker
            availableSlots={availabilitySlots}
            pickedCalendarDate={pickedCalendarDate}
            setPickedCalendarDate={setPickedCalendarDate}
            pickedSlot={pickedSlot}
            setPickedSlot={setPickedSlot}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : null}
        {activeStep === 2 ? (
          <CustomerBookingForm
            givenName={givenName}
            familyName={familyName}
            email={email}
            phone={phone}
            validPhone={validPhone}
            bookReason={bookReason}
            setGivenName={setGivenName}
            setFamilyName={setFamilyName}
            setEmail={setEmail}
            setPhone={setPhone}
            setBookReason={setBookReason}
            bookType={bookType}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : null}
        {activeStep === 3 ? <BookingConfirmStep /> : null}
        <LoadingBackdrop
          open={!!loading && (activeStep === 1 || activeStep === 2)}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    project(id: { eq: $id }) {
      id
      slug
      name
      availability
      meta {
        city
        zip_code
        url
        description
        fullDescription
        background_url
        logo_url
      }
    }
  }
`
