import React from "react"
import { Button, Paper, TextField, Typography } from "@material-ui/core"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const CustomPhoneInput = React.forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      label="Téléphone"
      style={{ marginBottom: 10 }}
      variant="outlined"
      name="phone"
    />
  )
})

const TextInput = ({ label, value, onChange, ...props }) => (
  <TextField
    variant="outlined"
    label={label}
    value={value || ""}
    onChange={onChange}
    style={{ marginBottom: 10 }}
    {...props}
  />
)

export default function CustomerBookingForm({
  givenName,
  familyName,
  email,
  phone,
  bookReason,
  setGivenName,
  setFamilyName,
  setEmail,
  setPhone,
  validPhone,
  setBookReason,
  bookType,
  handleBack,
  handleNext,
}) {
  const nextDisabled =
    !familyName ||
    familyName.length < 2 ||
    !givenName ||
    givenName.length < 2 ||
    !email ||
    !emailRegex.test(email) ||
    !bookReason ||
    bookReason.match(/\S+/g).length < 2 ||
    (bookType === "Phone" && (!phone || !validPhone))
  console.log({ phone })
  return (
    <Paper
      style={{
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
        padding: 10,
        maxWidth: 600,
        width: "100%",
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Précisez vos informations
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          marginBottom: 10,
          minWidth: 300,
        }}
      >
        <TextInput
          label="Prénom"
          value={givenName}
          onChange={e => setGivenName(e.target.value)}
          error={!!name && name.length < 2}
        />
        <TextInput
          label="Nom"
          value={familyName}
          onChange={e => setFamilyName(e.target.value)}
          error={!!familyName && familyName.length < 2}
        />
        <TextInput
          label="Email"
          value={email}
          onChange={e => setEmail(e.target.value.toLowerCase())}
          error={!!email && !emailRegex.test(email)}
        />
        <PhoneInput
          defaultCountry="FR"
          inputComponent={CustomPhoneInput}
          placeholder="Téléphone"
          value={phone}
          onChange={setPhone}
          error={phone && !validPhone}
        />
        {/* <TextField
          label="react-number-format"
          value={phone}
          onChange={setPhone}
          id="formatted-numberformat-input"
          InputProps={{
            inputComponent: CustomPhoneInput,
          }}
        /> */}

        {/* <TextInput
          label="Téléphone"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        /> */}
        <TextInput
          value={bookReason}
          onChange={e => setBookReason(e.target.value)}
          label="Motif du rendez-vous"
          multiline
          rows={4}
          placeholder="Motivez votre demande pour permettre à votre commerçant de préparer le rendez-vous"
          error={!!bookReason && bookReason.match(/\S+/g).length < 2}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={handleBack}
          style={{
            background: "white",
            border: "1px solid rgb(255,126,126)",
          }}
        >
          <Typography color="secondary">Retour</Typography>
        </Button>
        <div style={{ width: 10 }} />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          disabled={nextDisabled}
        >
          <Typography style={{ color: "white" }}>Suivant</Typography>
        </Button>
      </div>
    </Paper>
  )
}
