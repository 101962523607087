import { Button, Paper, Typography } from "@material-ui/core"
import React, { Fragment } from "react"
import useIsMobile from "../utils/useIsMobile"
import BookCalendar from "./BookCalendar"
import { makeStyles } from "@material-ui/core/styles"

const SlotButton = ({
  start,
  startISOString,
  end,
  str,
  setPickedSlot,
  selected,
  calendarStepButtonsRef,
}) => (
  <Button
    onClick={() => {
      setPickedSlot({
        start: startISOString,
        end: end.toISOString(),
      })
      calendarStepButtonsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }}
    variant="contained"
    style={{
      background: selected ? "rgb(11, 46, 91)" : "white",
      color: selected ? "white" : "rgb(11, 46, 91)",
      margin: 5,
      padding: 5,
    }}
  >
    {str}
  </Button>
)

const SlotsGroup = ({
  data,
  setPickedSlot,
  pickedTime,
  calendarStepButtonsRef,
}) => {
  return data.map(slotGroup => {
    const key = Object.keys(slotGroup)[0]
    return (
      <Fragment key={key}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              height: 1,
              background: "rgb(11, 46, 91)",
            }}
          />
          <Typography variant="h6" style={{ padding: 10 }} color="primary">
            {key === "morning" && "Matin"}
            {key === "afternoon" && "Après-midi"}
            {key === "evening" && "Soirée"}
          </Typography>
          <div
            style={{
              display: "flex",
              flex: 1,
              height: 1,
              background: "rgb(11, 46, 91)",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {Object.values(slotGroup)[0].map(
            ({ start, end, str, startISOString }) => {
              const selected = pickedTime && startISOString === pickedTime.start
              return (
                <SlotButton
                  key={str}
                  str={str}
                  // setFormValue={setFormValue}
                  setPickedSlot={setPickedSlot}
                  selected={selected}
                  start={start}
                  end={end}
                  calendarStepButtonsRef={calendarStepButtonsRef}
                  startISOString={startISOString}
                />
              )
            }
          )}
        </div>
      </Fragment>
    )
  })
}

const useSlotPickerStyles = isMobile =>
  makeStyles(theme => ({
    main: { display: "flex", flexDirection: "column", flex: 1 },
    layout: {
      display: "flex",
      flex: 1,
      ...(isMobile
        ? {
            flexDirection: "column",
          }
        : {
            flexDirection: "row",
            flexWrap: "wrap",
          }),
      width: "100%",
    },
    calendarPaper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: 266,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      ...(isMobile ? { alignSelf: "center" } : {}),
    },
    slotPaper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: 10,
    },
  }))

const getToday = () => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)
  return today
}
export default function SlotPicker({
  handleNext,
  handleBack,
  availableSlots,
  pickedCalendarDate,
  setPickedCalendarDate,
  pickedSlot,
  setPickedSlot,
}) {
  const isMobile = useIsMobile()
  const classes = useSlotPickerStyles(isMobile)()
  const slotsPaperRef = React.useRef()
  const calendarStepButtonsRef = React.useRef()
  const [tileDisabled, setTileDisabled] = React.useState(() => () => true)
  const [slotsForDate, setSlotsForDate] = React.useState([])
  React.useEffect(() => {
    if (!availableSlots) return
    console.log("availableSlots changed, running effect")
    const today = getToday()
    const slotsDates = new Set(
      availableSlots
        .filter(({ start }) => start >= today)
        .map(({ start }) => start.toLocaleDateString())
    )
    if (slotsDates.size === 0) {
      setTileDisabled(() => () => true)
      return
    }
    const firstSlotStartLocaleDateString = availableSlots[0].start.toLocaleDateString()
    const slotsArray = [...slotsDates]
    setTileDisabled(() => ({ date }) => {
      console.log("check disabled tile")
      if (date.toLocaleDateString() < firstSlotStartLocaleDateString)
        return true
      if (
        slotsArray.some(
          slotStartLocaleDateString =>
            slotStartLocaleDateString === date.toLocaleDateString()
        )
      )
        return false
      return true
    })
  }, [availableSlots])
  React.useEffect(() => {
    if (pickedCalendarDate) {
      const pickedLocaleDateString = pickedCalendarDate.toLocaleDateString()
      const slots = availableSlots.filter(
        ({ start }) => start.toLocaleDateString() === pickedLocaleDateString
      )
      const slotsGroups = []
      slotsGroups.push(
        {
          morning: slots
            .filter(
              ({ start }) => parseInt(start.toTimeString().split(":"), 10) < 12
            )
            .map(slot => ({
              ...slot,
              str: slot.start.toLocaleTimeString().slice(0, 5),
              startISOString: slot.start.toISOString(),
            })),
        },
        {
          afternoon: slots
            .filter(
              ({ start }) =>
                parseInt(start.toTimeString().split(":"), 10) >= 12 &&
                parseInt(start.toTimeString().split(":"), 10) < 18
            )
            .map(slot => ({
              ...slot,
              str: slot.start.toLocaleTimeString().slice(0, 5),
              startISOString: slot.start.toISOString(),
            })),
        },
        {
          evening: slots
            .filter(
              ({ start }) => parseInt(start.toTimeString().split(":"), 10) >= 18
            )
            .map(slot => ({
              ...slot,
              str: slot.start.toLocaleTimeString().slice(0, 5),
              startISOString: slot.start.toISOString(),
            })),
        }
      )
      setSlotsForDate(slotsGroups)
    }
  }, [pickedCalendarDate, availableSlots])
  console.log({ slotsForDate, pickedCalendarDate, pickedSlot })
  return (
    <div className={classes.main}>
      <div className={classes.layout}>
        <Paper className={classes.calendarPaper}>
          <BookCalendar
            availableSlots={availableSlots}
            pickedDate={pickedCalendarDate}
            setPickedDate={setPickedCalendarDate}
            slotsPaperRef={slotsPaperRef}
            tileDisabled={tileDisabled}
          />
        </Paper>
        <Paper ref={slotsPaperRef} className={classes.slotPaper}>
          <SlotsGroup
            data={slotsForDate}
            setPickedSlot={setPickedSlot}
            // setFormValue={setFormValue}
            pickedTime={pickedSlot}
            calendarStepButtonsRef={calendarStepButtonsRef}
          />
        </Paper>
      </div>
      <div ref={calendarStepButtonsRef} className={classes.buttonsContainer}>
        <Button
          variant="contained"
          onClick={handleBack}
          style={{
            background: "white",
            border: "1px solid rgba(255,126,126)",
          }}
        >
          <Typography color="secondary">Retour</Typography>
        </Button>
        <div style={{ width: 10 }} />
        <Button
          variant="contained"
          color="secondary"
          disabled={!pickedCalendarDate || !pickedSlot}
          onClick={handleNext}
        >
          <Typography style={{ color: "white" }}>Suivant</Typography>
        </Button>
      </div>
    </div>
  )
}
