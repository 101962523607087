import { addMonths } from "date-fns"
import React from "react"
import Calendar from "react-calendar"
import "./BookCalendar.css"

const getToday = () => {
  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0)
  today.setMilliseconds(0)
  return today
}

// const tileDisabled = availableSlots => {
//   const slotsDates = availableSlots.map(({ start }) =>
//     start.toLocaleDateString()
//   )
//   const today = getToday()

//   return ({ date }) => {
//     if (date < today) return true
//     if (
//       availableSlots.length > 0 &&
//       date.toLocaleDateString() < availableSlots[0].start.toLocaleDateString()
//     )
//       return true
//     if (
//       availableSlots.every(
//         (_, index) => slotsDates[index] !== date.toLocaleDateString()
//       )
//     )
//       return true
//     return false
//   }
// }

function BookCalendar({
  availableSlots,
  pickedDate,
  setFormValue,
  setPickedDate,
  slotsPaperRef,
  tileDisabled,
}) {
  return (
    <Calendar
      minDetail="month"
      minDate={new Date()}
      maxDate={addMonths(new Date(), 1)}
      onChange={date => {
        setPickedDate(date)
        // setFormValue("pickedDate")(date)
        slotsPaperRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
      }}
      value={pickedDate}
      tileDisabled={tileDisabled}
    />
  )
}

export default BookCalendar
