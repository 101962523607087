import React from "react"
import { Avatar, Box, Button, Paper, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

export default function BookingConfirmStep() {
  return (
    <Paper
      style={{
        display: "flex",
        alignSelf: "center",
        flexDirection: "column",
        padding: 10,
        maxWidth: 600,
        width: "100%",
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Consultez votre messagerie pour confirmer votre rendez-vous
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          marginBottom: 10,
          minWidth: 300,
          alignItems: "center",
        }}
      >
        <Avatar
          style={{
            width: 100,
            height: 100,
            border: "2px solid #ff6363",
            backgroundColor: "white",
          }}
        >
          <MailOutlineIcon style={{ color: "#ff6363", fontSize: 56 }} />
        </Avatar>
        <Box my={3}>
          <Typography align="center" my={3}>
            Un email de confirmation vous a été envoyé. Votre demande expirera
            dans 5 minutes
          </Typography>
        </Box>
        <Button
          variant="contained"
          style={{ backgroundColor: "rgb(255,126,126)", color: "white" }}
          component={Link}
          to="/"
        >
          Retourner à l'accueil
        </Button>
      </div>
    </Paper>
  )
}
